import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import laptopImg from '../../Assets/about.png';
import Particle from '../Particle';
import Aboutcard from './AboutCard';
import Techstack from './Techstack';

function About() {
  return (
    <Container fluid className='about-section'>
      <Particle />
      <Container>
        <Row style={{ justifyContent: 'center', padding: '10px' }}>
          <Col
            md={7}
            style={{
              justifyContent: 'center',
              paddingTop: '30px',
              paddingBottom: '50px',
            }}>
            <h1 style={{ fontSize: '2.1em', paddingBottom: '20px' }}>
              <strong className='purple'>Who am I?</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: '120px', paddingBottom: '50px' }}
            className='about-img'>
            <img src={laptopImg} alt='about' className='img-fluid' />
          </Col>
        </Row>
        <Techstack />
      </Container>
    </Container>
  );
}

export default About;
