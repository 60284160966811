import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Api } from 'tabler-icons-react';
import ReactTooltip from 'react-tooltip';
import uuid from 'react-uuid';

import {
  SiApachekafka,
  SiAuth0,
  SiAzuredevops,
  SiAzurefunctions,
  SiCsharp,
  SiDocker,
  SiDotnet,
  SiFlask,
  SiJavascript,
  SiKubernetes,
  SiMicrosoftazure,
  SiMicrosoftsqlserver,
  SiMongodb,
  SiPython,
  SiReact,
  SiRedis,
} from 'react-icons/si';

const tooltipConfig = [
  { key: 'iconApi', tooltip: 'REST APIs' },
  { key: 'iconPython', tooltip: 'Python' },
  { key: 'iconCsharp', tooltip: 'C# / .NET' },
  { key: 'iconDotnet', tooltip: 'C# / .NET' },
  { key: 'iconJs', tooltip: 'Javascript' },
  { key: 'iconReact', tooltip: 'React' },
  { key: 'iconSqlServer', tooltip: 'Microsoft SQL Server' },
  { key: 'iconFlask', tooltip: 'Python Flask' },
  { key: 'iconDocker', tooltip: 'Docker' },
  { key: 'iconKubernetes', tooltip: 'Kubernetes' },
  { key: 'iconAzure', tooltip: 'Azure' },
  { key: 'iconDevops', tooltip: 'DevOps' },
  { key: 'iconAzureFunctions', tooltip: 'Azure Functions' },
  { key: 'iconRedis', tooltip: 'Redis' },
  { key: 'iconMongo', tooltip: 'MongoDB' },
  { key: 'iconOauth', tooltip: 'OAuth' },
  { key: 'iconKafka', tooltip: 'Apache / Confluent Kafka' },
];

const IconTooltips = () => {
  return tooltipConfig.map((config) => (
    <ReactTooltip id={config.key} place='top' effect='solid'>
      {config.tooltip}
    </ReactTooltip>
  ));
};

function Techstack() {
  return (
    <>
      <Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
        <h1 className='project-heading'>
          {/* <strong className='purple'></strong> */}
          Core Tech
        </h1>

        <Col
          xs={4}
          md={2}
          className='tech-icons'
          data-tip
          data-for='iconCsharp'>
          <span>
            <SiCsharp />
            <SiDotnet />
          </span>
        </Col>

        <Col
          xs={4}
          md={2}
          className='tech-icons'
          data-tip
          data-for='iconPython'>
          <SiPython />
        </Col>
        <Col xs={4} md={2} className='tech-icons' data-tip data-for='iconJs'>
          <SiJavascript />
        </Col>
      </Row>
      <Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
        <h1>Infrastructure</h1>
        <Col xs={4} md={2} className='tech-icons' data-tip data-for='iconAzure'>
          <SiMicrosoftazure />
        </Col>
        <Col
          xs={4}
          md={2}
          className='tech-icons'
          data-tip
          data-for='iconDevops'>
          <SiAzuredevops />
        </Col>
        <Col
          xs={4}
          md={2}
          className='tech-icons'
          data-tip
          data-for='iconDocker'>
          <SiDocker />
        </Col>
        <Col
          xs={4}
          md={2}
          className='tech-icons'
          data-tip
          data-for='iconKubernetes'>
          <SiKubernetes />
        </Col>
        <Col
          xs={4}
          md={2}
          className='tech-icons'
          data-tip
          data-for='iconAzureFunctions'>
          <SiAzurefunctions />
        </Col>
        <Col xs={4} md={2} className='tech-icons' data-tip data-for='iconMongo'>
          <SiMongodb />
        </Col>
        <Col xs={4} md={2} className='tech-icons' data-tip data-for='iconRedis'>
          <SiRedis />
        </Col>
        <Col xs={4} md={2} className='tech-icons' data-tip data-for='iconOauth'>
          <SiAuth0 />
        </Col>
      </Row>
      <Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
        <h1>Web Development</h1>
        <Col xs={4} md={2} className='tech-icons' data-tip data-for='iconApi'>
          <Api size={72} />
        </Col>
        <Col xs={4} md={2} className='tech-icons' data-tip data-for='iconFlask'>
          <SiFlask />
        </Col>
        <Col xs={4} md={2} className='tech-icons' data-tip data-for='iconMongo'>
          <SiMongodb />
        </Col>
        <Col
          xs={4}
          md={2}
          className='tech-icons'
          data-tip
          data-for='iconKubernetes'>
          <SiKubernetes />
        </Col>
        <Col
          xs={4}
          md={2}
          className='tech-icons'
          data-tip
          data-for='iconDocker'>
          <SiDocker />
        </Col>
        <Col
          xs={4}
          md={2}
          className='tech-icons'
          data-tip
          data-for='iconSqlServer'>
          <SiMicrosoftsqlserver />
        </Col>
        <Col xs={4} md={2} className='tech-icons' data-tip data-for='iconKafka'>
          <SiApachekafka />
        </Col>
        <Col xs={4} md={2} className='tech-icons' data-tip data-for='iconReact'>
          <SiReact />
        </Col>
      </Row>
      <IconTooltips />
    </>
  );
}

export default Techstack;
