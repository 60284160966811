import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import template from '../../Assets/Projects/template.png';
import Particle from '../Particle';
import ProjectCard from './ProjectCards';

function Projects() {
  return (
    <Container fluid className='project-section'>
      <Particle />
      <Container>
        <h1 className='project-heading'>
          My <strong className='purple'>Projects </strong>
        </h1>
        <p style={{ color: 'white' }}>Collection of random personal projects</p>
        <Row style={{ justifyContent: 'center', paddingBottom: '10px' }}>
          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={template}
              isBlog={false}
              title='Template'
              description='Placeholder'
              ghLink='https://github.com/danleonard-nj'
              demoLink='https://github.com/danleonard-nj'
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
