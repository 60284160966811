import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AiFillGithub, AiOutlineMail } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import Particle from '../Particle';
import Type from './Type';

const HomeContent = () => {
  return (
    <Container fluid className='home-about-section' id='about'>
      <Container>
        <Row>
          <Col md={8} className='home-about-description'>
            <h1 style={{ fontSize: '2.6em' }}>
              <span className='purple'> INTERESTS </span> AND STUFF
            </h1>
            <p className='home-about-body'>
              Software engineer @ Microsoft
              <br />
              <br />
              Generally work with
              <b className='purple'> C# / .NET, Python, Javascript </b>
              <br />
              <br />
              K8S enthusiast
              <br />
            </p>
          </Col>
          <Col md={4} className='myAvtar'></Col>
        </Row>
        <Row>
          <Col md={12} className='home-about-social'>
            <h1>CONTACT ME</h1>
            <p></p>
            <ul className='home-about-social-links'>
              <li className='social-icons'>
                <a
                  href='https://github.com/danleonard-nj'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'>
                  <AiFillGithub />
                </a>
              </li>
              <li className='social-icons'>
                <a
                  href='mailto:dcl525@gmail.com'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'>
                  <AiOutlineMail />
                </a>
              </li>
              {/* <li className='social-icons'>
                <a
                  href='https://twitter.com/Soumyajit4419'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'>
                  <AiOutlineTwitter />
                </a>
              </li> */}
              <li className='social-icons'>
                <a
                  href='https://www.linkedin.com/in/soumyajit4419/'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'>
                  <FaLinkedinIn />
                </a>
              </li>
              {/* <li className='social-icons'>
                <a
                  href='https://www.instagram.com/s.o.u.m.y.a_j.i.t/'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour home-social-icons'>
                  <AiFillInstagram />
                </a>
              </li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

function Home() {
  return (
    <section>
      <Container fluid className='home-section' id='home'>
        <Particle />
        <Container className='home-content'>
          <Row>
            <Col md={7} className='home-header'>
              <h1 style={{ paddingBottom: 15 }} className='heading'>
                Hi,
              </h1>

              <h1 className='heading-name'>
                I'm
                <strong className='main-name'> Dan Leonard</strong>
              </h1>

              <div style={{ padding: 50, textAlign: 'left' }}>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              {/* <img
                src={homeLogo}
                alt='home pic'
                className='img-fluid'
                style={{ maxHeight: '450px' }}
              /> */}
            </Col>
          </Row>
        </Container>
      </Container>
      <HomeContent />
    </section>
  );
}

export default Home;
