import React from 'react';
import Card from 'react-bootstrap/Card';
import { ArrowBarRight } from 'tabler-icons-react';

function AboutCard() {
  return (
    <Card className='quote-card-view'>
      <Card.Body>
        <blockquote className='blockquote mb-0'>
          <p style={{ textAlign: 'justify' }}>
            My name is <span className='purple'>Dan Leonard </span>
            and I'm a professional/hobby software engineer living in{' '}
            <span className='purple'>Phoeniz, AZ</span>, and originally from{' '}
            <span className='purple'>Camden County, New Jersey</span>. I
            currently work for online auto retailer{' '}
            <span className='purple'>Carvana</span> in the logistics division.
            <br />
            <br />
            Aside from tech, I'm also into:
          </p>
          <ul>
            <li className='about-activity'>
              <ArrowBarRight /> <span className='purple'>Music</span> (guitar
              and piano mainly)
            </li>
            <li className='about-activity'>
              <ArrowBarRight /> <span className='purple'>Electronics</span>{' '}
              hobby projects (RaspberryPis, etc)
            </li>
            <li className='about-activity'>
              <ArrowBarRight />
              <span className='purple'> Tube amps</span> (playing and restoring)
            </li>
            <li className='about-activity'>
              <ArrowBarRight /> <span className='purple'>Hiking</span>
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
